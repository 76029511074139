:root {
    --main-padding: 100px;
}

.hero-section{
    width: calc(100% - 2*var(--main-padding));
    height: 100vh;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 var(--main-padding);
}
.herobutton{
    background: #1A73E8;
    color: white;
}
.hero-maintext{
    display: flex;
}
.hero-maintext{
    font-size: 60px;
    color: #202020;
}
.hero-maintext.first{
    font-weight: 700;
}
.hero-maintext.second{
    font-weight: 200;
    width: 350px;
    height: 156px;
    overflow: hidden;
    
}
.hero-maintext.second span {
position: relative;

animation: textAnimation 21s ease-in infinite;

}
@keyframes textAnimation {
    0% ,
    100% {
  
        top: 0;
    }
    20% {
        top: 0;
    }
    25% {
        top: calc(-1 * 156px);
    }
    45% {
        top: calc(-1 * 156px);
    }
    50% {
        top: calc(-2 * 156px);
    }
    70% {
        top: calc(-2 * 156px);
    }
    75% {
        top: calc(-3 * 156px);
    
    }
    95% {
        top: calc(-3 * 156px);
        color: #202020;
     
    }

}
.hero-smalltext {
    color: #202020;
    font-size: 18px;
    margin: 50px 0 30px 0 ;
    width: 500px;
}
.right-hero {
    flex: 0 0 auto;
        width: 50%;
}
.video-container {
    position: relative;
    perspective: 1500px;
    perspective-origin: 50% 0;
    display: flex;
    justify-content: end;
}
.video-container .video{
    position: relative;
    max-width: 660px;
    max-height: 420px;
    height: 100%;
    width: 100%;
    padding: 1.5rem 2rem;
    border-radius: 0.9375rem;
    box-shadow: 6px 18px 60px 0 rgba(0, 0, 0, 0.12);
    background: #ffffff;
    display: flex;
    align-items: center;
    transform: rotateX(15deg) rotateY(-35deg) rotate(2deg);
    transform-style: preserve-3d;
}
.video-container .video video {
    width: 100%;
    border-radius: 0.9375rem;
    user-select: none;
    pointer-events: none;
}