.main-header{
    z-index: 100;
    width: 100%;
    position: fixed;
    padding: 1.75rem 0 0.56rem;
    background: #fff;
    display: flex;
   
}

.header-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 100px;
}

.header-menu {
    display: flex;
}
.header-logo{
   
    color: black;
    font-weight: 600;
  
    font-size: 30px;
    
}
.menu-button {
    height: 40px;
    line-height: 40px;
    padding: 0px 18px 0px 18px;
    width: auto;
    font-weight: 500;
    display: block;
    color: black;
    text-transform: none;
    text-decoration: none;
    text-align: left;
    font-size: 16px;
    border: 1px solid rgba(0, 0, 0, 0);
}
.menu-button:hover {
    border: 1px solid ;
    border-radius: 0.5rem !important;
}
.header-buttons{
    display: flex;
    gap: 0.75rem;
}
.grey-country-button{
    width: 62.5px;
    height: 48px;
    font-weight: 400;
    background: #F5F5F7;
  
}
.book-demo-button{
 
    background: #1A73E8;
    color: white;
font-weight: 500;
  
}